import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from './../UserContext';

export const withAuth = (WrappedComponent) => {
  const AuthComponent = (props) => {
    const { user, loading } = useUser();

    if (loading) {
      return <div>Loading...</div>;
    }

    if (!user) {
      return <Navigate to="/login" />;
    }

    return <WrappedComponent {...props} user={user} />;
  };

  return AuthComponent;
};

export default withAuth;